<template>
  <base-page
    class="page"
    title="Results"
  >
    <template
      #aside
    >
      Sidebar
    </template>
    <template
      #default
    >
      <base-card>
        <base-title :level="1">
          Previously Asked
        </base-title>
        <base-paragraph>
          Welcome to the Results page.
        </base-paragraph>
      </base-card>
    </template>
  </base-page>
</template>

<script>
export default {};
</script>
